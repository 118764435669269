export class PerfilPago {

    constructor(
        public persona: number,
        public posEntryMode: string,
        public merchantUser: string,
        public merchantPassword: string,
        public merchantUserUsd: string,
        public merchantPasswordUsd: string,
        public terminalId: string,
        public merchant: string,
        public terminalIdUsd: string,
        public merchantUsd: string,
        public plan: number,
        public estado: number,
        public colorFondoEncabezadoVoucher: string,
        public colorFuenteEncabezadoVoucher: string,
        public tasaComision?: number

    ) {}

}
